<template>
  <div class="card-panel">
    <div class="card-content" v-if="cardType.length">
      <div class="header">
        <div class="header-left">
          <Carousel v-model="carouselIndex" dots="none" @on-change="handleCarouselChange" loop>
            <CarouselItem v-for="item in cardType" :key="item.name">
              <div class="card-pic" :style="{ backgroundImage: `url(${item.tp})` }"></div>
            </CarouselItem>
          </Carousel>
          <div class="header-content">
            <div class="card-info">
              <div class="card-name">{{ selectedCard.name }}</div>
              <div class="card-tip">卡系简介：{{ selectedCard.jj }}</div>
            </div>
            <div class="card-overview">
              <div class="card-circle">
                <p>{{ cardStatistics.hyzrs || 0 }}</p>
                <p>会员总人数</p>
              </div>
              <div class="card-circle">
                <p>{{ cardStatistics.kxhyrs || 0 }}</p>
                <p>卡系会员人数</p>
              </div>
              <div class="card-circle">
                <p>{{ cardStatistics.wkkrs || 0 }}</p>
                <p>未开卡人数</p>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <p class="header-title">卡系寄存清单</p>
          <table class="table">
            <tr>
              <th></th>
              <th style="width: 40%"></th>
              <th>已消耗次数</th>
              <th>剩余次数</th>
              <th>服务总次数</th>
            </tr>
            <tr v-for="(item, index) in depositList" :key="index">
              <th>{{ item.name }}</th>
              <td>
                <Progress :percent="(item.zcs === 0 ? 0 : (item.xh / item.zcs).toFixed(2)) * 100" />
              </td>
              <td>{{ item.xh }}</td>
              <td>{{ item.sy }}</td>
              <td>{{ item.zcs }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="card-content bg-white pt-20px">
      <div class="header">
        <div class="header-action">
          <span>注册手机号</span>
          <Input v-model="cardQuery.mobile" placeholder="请输入注册手机号" style="width: 150px" />
          <span>姓名</span>
          <Input v-model="cardQuery.name" placeholder="请输入姓名" style="width: 150px" />
          <span>卡系状态</span>
          <Select v-model="cardQuery.kxzt" style="width: 150px">
            <Option value="全部">全部</Option>
            <Option value="有效期内">有效期内</Option>
            <Option value="会员">会员</Option>
            <Option value="非会员">非会员</Option>
            <Option value="暂停服务">暂停服务</Option>
            <Option value="已过期">已过期</Option>
          </Select>
        </div>
        <div class="header-btn">
          <Button type="info">导出报表</Button>
        </div>
      </div>
      <div class="header">
        <div class="header-action">
          <span>卡系注册时间</span>
          <DatePicker
            type="daterange"
            placeholder="选择日期"
            style="width: 200px"
            @on-change="onChangeTime"
          ></DatePicker>
          <span>首次开卡员工</span>
          <Select v-model="cardQuery.staff_id" style="width: 125px">
            <Option v-for="item in staffs" :key="item.id" :value="item.id">{{ item.true_name }}</Option>
          </Select>
          <Button type="info" @click="handleQuery">查询</Button>
          <Button @click="resetQuery">重置</Button>
        </div>
        <div class="header-btn">
          <Button type="success" @click="handleApplyForCard">开卡</Button>
          <Button type="info" @click="applyCardService(3)">重启服务</Button>
          <Button type="primary" @click="handleShowPauseModal">暂停服务</Button>
          <Button type="warning" @click="applyCardService(4)">续卡</Button>
          <Button type="error" @click="applyCardService(5)">退卡</Button>
        </div>
      </div>
      <div class="table-panel">
        <Table :columns="dataColumns" :data="dataList" @on-selection-change="handleDataSelect"></Table>
        <div>
          <span class="records">共 {{ dataTotal }} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="dataTotal"
              :current="dataCurrentPage"
              :page-size="cardQuery.size"
              @on-change="changeDataPage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="会员开卡"
      :closable="false"
      :mask-closable="false"
      width="800"
      class-name="vertical-center-modal"
      v-if="cardType.length"
    >
      <div class="card-modal">
        <div class="card-pic" :style="{ backgroundImage: `url(${selectedCard.tp})` }"></div>
        <div class="card-content">
          <div class="card-info">
            <div class="card-name">{{ selectedCard.name }}</div>
            <div class="card-tip">卡系简介：{{ selectedCard.jj }}</div>
          </div>
        </div>
      </div>
      <div class="card-modal-label">卡系项目</div>
      <table class="card-modal-table" v-if="'智惠通' === selectedCard.name">
        <tr>
          <td colspan="2">类目</td>
          <td>项目</td>
          <td style="width: 100px;">次数</td>
          <td style="width: 100px;">康币</td>
        </tr>
        <tr>
          <td rowspan="15">个性化方案</td>
          <td rowspan="10">靶向功能特检</td>
          <td>适生®治疗型健康管理系统</td>
          <td>实时专享</td>
          <td>-</td>
        </tr>
        <tr>
          <td>基因检测</td>
          <td>1</td>
          <td>4300</td>
        </tr>
        <tr>
          <td>H360 持续葡萄糖检测系统</td>
          <td>1</td>
          <td>3000</td>
        </tr>
        <tr>
          <td>72H 长程心电检测</td>
          <td>1</td>
          <td>600</td>
        </tr>
        <tr>
          <td>Q6 血管质量损伤程度检测</td>
          <td>2</td>
          <td>300</td>
        </tr>
        <tr>
          <td>全息胚眼底血管质量检测</td>
          <td>1</td>
          <td>300</td>
        </tr>
        <tr>
          <td>URC 早期癌症风险筛查</td>
          <td>1</td>
          <td>300</td>
        </tr>
        <tr>
          <td>定量超声骨密度监测</td>
          <td>1</td>
          <td>300</td>
        </tr>
        <tr>
          <td>FEV 肺呼吸通透功能检测</td>
          <td>2</td>
          <td>150</td>
        </tr>
        <tr>
          <td>FMS 功能性运动测试</td>
          <td>2</td>
          <td>150</td>
        </tr>
        <tr>
          <td rowspan="5">健康风险监测</td>
          <td>体质征态全息监测</td>
          <td>10</td>
          <td>50</td>
        </tr>
        <tr>
          <td>MDI 全息胚血液信息检测</td>
          <td>2</td>
          <td>50</td>
        </tr>
        <tr>
          <td>五点血糖代谢监测</td>
          <td>2</td>
          <td>100</td>
        </tr>
        <tr>
          <td>IPP 超细胞衰退程度检测</td>
          <td>2</td>
          <td>50</td>
        </tr>
        <tr>
          <td>ACSM 心肺耐力功能测试</td>
          <td>1</td>
          <td>100</td>
        </tr>
        <tr>
          <td rowspan="8">促进型服务</td>
          <td rowspan="8">云端/门店服务</td>
          <td>治疗型健康管理大型方案及指导</td>
          <td>1</td>
          <td>200</td>
        </tr>
        <tr>
          <td>风险评估方案</td>
          <td>1</td>
          <td>200</td>
        </tr>
        <tr>
          <td>营养调节方案及指导</td>
          <td>10</td>
          <td>90</td>
        </tr>
        <tr>
          <td>能量代谢方案及指导</td>
          <td>10</td>
          <td>90</td>
        </tr>
        <tr>
          <td>训练治疗方案及指导</td>
          <td>4</td>
          <td>90</td>
        </tr>
        <tr>
          <td>复健达成方案及指导</td>
          <td>4</td>
          <td>90</td>
        </tr>
        <tr>
          <td>康一生®健康智能监测手表</td>
          <td>1</td>
          <td>3000</td>
        </tr>
        <tr>
          <td>康一生®智能蓝牙血压仪</td>
          <td>1</td>
          <td>1000</td>
        </tr>
      </table>
      <table class="card-modal-table" v-else>
        <tr>
          <td>项目</td>
          <td>项目次数</td>
          <td style="width: 100px;">康币</td>
        </tr>
        <tr v-for="(item, index) in selectedCard.xmlb" :key="index">
          <td>{{ item.xm }}</td>
          <td>{{ item.xmcs }}</td>
          <td>{{ item.xmdj }}</td>
        </tr>
      </table>
      <div class="upload-btn">
        <span class="mr10">{{ currentMember.member_name }}</span>
        <span class="mr10">{{ currentMember.mobile }}</span>
        <Upload
          action="/api/check/photo/upload"
          name="photo"
          :show-upload-list="false"
          :max-size="1024"
          :format="['png', 'jpg', 'jpeg']"
          :on-format-error="handleFormatError"
          :on-success="handleUploadSuccess"
          :on-exceeded-size="handleMaxSize"
        >
          <Button type="success" class="mr10">上传{{selectedCard.name}}卡系会员签字单</Button>
        </Upload>
        <a v-if="signSrc" :href="signSrc" target="_blank">查看会员签字单</a>
      </div>
      <div slot="footer">
        <Button type="text" class="mr10" @click="closeModal">取消</Button>
        <Button type="primary" @click="confirmApplyForCard">确定</Button>
      </div>
    </Modal>
    <Modal v-model="showPauseModal" title="暂停服务" class-name="vertical-center-modal" width="600">
      <div class="upload-btn">
        <span class="mr10">暂停天数</span>
        <span class="mr10">
          <InputNumber v-model="pauseDays" placeholder="请输入" :min="0"></InputNumber>
        </span>
      </div>
      <div slot="footer">
        <Button type="text" class="mr10" @click="closeModal">取消</Button>
        <Button type="primary" @click="handleConfirmPause">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			signSrc: '',
			carouselIndex: 0,
			selectedCard: {},
			cardQuery: {
				page: 1,
				size: 30,
				mobile: '',
				name: '',
				kxzt: '',
				start: '',
				end: '',
				staff_id: '',
			},
			dataColumns: [
				{
					type: 'selection',
					width: 60,
				},
				{
					title: '卡系名称',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#ff8400',
								},
							},
							params.row.kxmc,
						);
					},
				},
				{
					title: '会员名称',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.member_name,
						);
					},
				},
				{ title: '手机号', key: 'mobile', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{
					title: '卡系状态',
					key: 'kxzt',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.kxzt === 2 ? '#4cae32' : params.row.kxzt === 3 ? '#f07844' : 'red',
								},
							},
							params.row.kxzt_name,
						);
					},
				},
				{ title: '操作状态', key: 'czzt', align: 'center' },
				{
					title: '卡系有效期',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#4495f0',
								},
							},
							params.row.kxyxq,
						);
					},
				},
				{ title: '开卡员工', key: 'kkyg', align: 'center' },
				{ title: '最近服务时间', key: 'zjfwsj', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						const actions = [
							h(
								'Button',
								{
									attrs: {
										type: 'info',
										size: 'small',
									},
									on: {
										click: () => {
											this.$router.push(`/base/member/detail/${params.row.member_id}/card`);
										},
									},
								},
								'查看',
							),
						];
						return actions;
					},
				},
			],
			cardType: [],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			selectedDataItem: [],
			staffs: [],
			cardStatistics: {},
			showModal: false,
			currentMember: {},
			isOpenCard: false,
			signId: '',
			depositList: [],
			pauseDays: 30,
			showPauseModal: false,
		};
	},
	created() {
		cardService.getCardType().then((data) => {
			this.cardType = data;
			this.selectedCard = data[0];
			this.getCardOverview();
			this.getStaffs();
			this.getDepositList();
		});
	},
	methods: {
		getStaffs() {
			cardService
				.getStaffs({
					kxlx_id: this.selectedCard.id,
					user_type: 1,
				})
				.then((data) => {
					this.staffs = data;
				});
		},
		getDepositList() {
			cardService.depositList({ kxlx_id: this.selectedCard.id }).then((data) => {
				this.depositList = data;
			});
		},
		onChangeTime(val) {
			this.cardQuery.start = val[0];
			this.cardQuery.end = val[1];
		},
		getCardOverview() {
			this.cardQuery.kxlx_id = this.selectedCard.id;
			cardService.getCardOverview(this.cardQuery).then((data) => {
				this.dataTotal = data.row_size;
				this.dataCurrentPage = data.cur_page;
				this.dataList = data.list;
				this.cardStatistics = data.tj;
			});
		},
		handleQuery() {
			this.cardQuery.page = 1;
			this.getCardOverview();
		},
		changeDataPage(page) {
			this.cardQuery.page = page;
			this.getCardOverview();
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection;
		},
		applyCardService(status, needReload = true) {
			return new Promise((resolve, reject) => {
				if (status !== 1 && !this.checkCardStatus()) return reject();
				const params = {
					member_id: this.isOpenCard
						? this.currentMember.member_id
						: this.selectedDataItem.map((item) => item.member_id).join(','),
					kxlx_id: this.selectedCard.id,
					sqnr: status,
					qzd_id: this.signId,
				};
				if (status === 2) params.ztts = this.pauseDays;
				cardService.checkCardStatus(params).then(() => {
					cardService.applyCardService(params).then(() => {
						if (needReload) this.getCardOverview();
						this.$Message.success('操作成功');
						return resolve();
					});
				});
			});
		},
		handleShowPauseModal() {
			this.showPauseModal = true;
		},
		handleConfirmPause() {
			this.applyCardService(2);
			this.showPauseModal = false;
		},
		handleApplyForCard() {
			if (!this.checkCardStatus()) return;
			this.currentMember = this.selectedDataItem.shift();
			this.isOpenCard = true;
			this.showModal = true;
		},
		closeModal() {
			this.isOpenCard = false;
			this.showModal = false;
			this.signSrc = '';
			this.signId = '';
		},
		confirmApplyForCard() {
			if (!this.signId) {
				this.$Message.warning('请上传会员签字单');
				return;
			}
			const isLastOne = this.selectedDataItem.length === 0;
			if (isLastOne) this.showModal = false;
			this.applyCardService(1, isLastOne).then(() => {
				if (!isLastOne) this.currentMember = this.selectedDataItem.shift();
				this.signSrc = '';
				this.signId = '';
			});
		},
		checkCardStatus() {
			if (!this.selectedDataItem.length) {
				this.$Message.warning('请选择会员');
				return false;
			} else {
				const target = this.selectedDataItem[0].kxzt;
				if (this.selectedDataItem.some((el) => el.kxzt !== target)) {
					this.$Message.warning('请确保选中会员的卡系状态完全一致');
					return false;
				}
				return true;
			}
		},
		handleCarouselChange(old, index) {
			// 重置页面数据
			this.dataTotal = 0;
			this.dataList = [];
			this.cardStatistics = {};
			this.selectedCard = this.cardType[index];
			this.cardQuery.page = 1;
			this.$nextTick(() => {
				this.getCardOverview();
				this.getStaffs();
				this.getDepositList();
			});
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，文件大小不能超过 1M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg 或 jpeg 格式的文件。',
			);
		},
		handleUploadSuccess(response) {
			if (response.c === 0) {
				this.signSrc = response.data.photo;
				this.signId = response.data.id;
			} else {
				this.$Message.error('上传失败，请重试');
			}
		},
		resetQuery() {
			this.cardQuery = {
				page: 1,
				size: 30,
				mobile: '',
				name: '',
				kxzt: '',
				start: '',
				end: '',
				staff_id: '',
			};
		},
	},
};
</script>

<style lang="less" scoped>
.card-content {
  margin-bottom: 20px;
}
.header {
  background-color: #fff;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
  &-left {
    flex: 1;
    padding: 10px 20px;
    border-right: #c8c8c8 solid 1px;
    display: flex;
    .ivu-carousel {
      width: 402px;
    }
  }
  &-right {
    flex: 1;
    padding: 10px 20px;
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
  }
  &-content {
    flex: 1;
    margin-left: 15px;
  }
  &-action {
    span {
      margin: 0 15px;
    }
  }
  &-btn {
    margin-right: 20px;
  }
}
.bg-white {
  background-color: #fff;
}
.pt-20px {
  padding-top: 20px;
}
.table-panel {
  padding: 0 20px;
}
.card-pic {
  height: 255.2px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.card-name {
  font-size: 18px;
  font-weight: bold;
  color: #fdad00;
  margin-top: 15px;
}
.card-tip {
  margin-top: 5px;
  color: #666;
}
.card-overview {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
}
.header-tip {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.2;
}
table {
  font-size: 16px;
  width: 100%;
  td,
  th {
    padding: 5px;
    text-align: center;
  }
  th {
    color: #8c8c8c;
    font-size: 14px;
  }
}
.card-modal {
  display: flex;
  .card-pic {
    height: 170.1px;
    width: 267.7px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
  }
  .card-name {
    font-size: 18px;
    font-weight: bold;
    color: #fdad00;
  }
  .card-tip {
    margin-top: 20px;
    color: #666;
  }
}
.card-modal-label {
  background-color: #51c78a;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  color: #fff;
}
.card-modal-table {
  font-size: 14px;
  td {
    border: #eee 1px solid;
    padding: 5px;
  }
}
.card-circle {
  background-color: #f2f2f2;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 12px;
  p:first-child {
    color: #fdad00;
    font-size: 22px;
    margin: 20px 0 5px;
  }
}
.upload-btn {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.mr10 {
  margin-right: 10px;
}
</style>
